<template>

    <a-layout-content>
        <div   style="background:#fff;padding:20px;  ">
            <a-row class="yj-form">
                <a-col :span="24">
                    <a-button type="primary" style="float:right" @click="add();">新建地点</a-button>
                </a-col>
            </a-row>
            <!--:customRow="handleClickRow"-->
            <a-table :columns="columns"
                     rowKey="ID"
                     :data-source="tableData"
                     :pagination="pagination"
                     @change="pagerChange"
                     class="yj-table-img">

                <template slot="operation" slot-scope="text, record">

                    <a href="#" @click="onEdit(record.ID)" style="margin-right:20px;">编辑</a>

                    <a href="#" @click="onDelete(record.ID)">删除</a>

                </template>
            </a-table>
        </div>
    </a-layout-content>

</template>
<style type="text/css">
 

    #title {
        height: 200px;
    }
</style>
<script>
    import http from "@/Plugin/Http.js";


    import { message } from 'ant-design-vue'

    export default {
        name: "QuestionList",
        data() {
            return {
                columns: [
                    {
                        title: "名称",
                        dataIndex: "Name",
                        width: 150,
                        ellipsis: true,
                        align: "center"
                    },
                    {
                        title: "地址",
                        dataIndex: "Address",
                        width: 250
                    },
                    {
                        title: "联系人",
                        dataIndex: "LinkMan",
                        width: 200
                    },
                    {
                        title: "联系电话",
                        dataIndex: "Phone",
                        width: 200
                    },
                    {
                        title: "操作",
                        dataIndex: "operation",
                        scopedSlots: { customRender: 'operation' },
                        width: 300
                    }
                ],
                modelDisplay: false,
                search: '',
                tableData: [],
                id: "",
                title: "",
                brief: "",
                pagination: {
                    total: 0,
                    defaultPageSize: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                }
            }
        },
        methods: {
            add: function () {
                var self = this;
                //self.$emit("addSite", "");

                self.$router.push({
                    name: "ShopManage_SiteDetail", query: {

                    }
                })
            },
            pagerChange: function (item) {
                var self = this;

                self.pagination.current = item.current;
                self.getTableData();
            },

            onEdit: function (data) {

                var self = this;
                //self.$emit("editSite", data);
                self.$router.push({
                    name: "ShopManage_SiteDetail", query: {
                        ID: data
                    }
                })
            },

            onDelete: function (data) {

                var self = this;
                self.$confirm({
                    title: '确定删除该地点吗',
                    content: '',
                    okText: '是',
                    okType: 'danger',
                    cancelText: '否',
                    onOk() {
                        self.deleteQuestionDetail(data);
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });
            },

            deleteQuestionDetail: function (id) {
                var self = this;
                var op = {
                    url: "/ShopModule/ShopManage/DelShopSite",
                    data: {
                        id: id,
                    },
                    OnSuccess: function (data) {
                        console.log(1234);
                        console.log(data);
                        if (data.data == true) {
                            message.success(
                                '删除成功',
                                1.5,
                                function () {
                                    self.getTableData();
                                    //self.$router.push({
                                    //    name: "ShopManage_ShopManage", query: {
                                    //        showName: "SiteList"
                                    //    }
                                    //})


                                }

                            );
                        }
                    }
                };
                http.Post(op);
            },

            getTableData: function () {
                var self = this;
                var op = {
                    url: "/ShopModule/ShopManage/GetSiteList",
                    data: {
                        pageIndex: self.pagination.current,
                    },
                    OnSuccess: function (data) {
                        self.tableData = data.data.Data;
                        self.pagination.total = data.data.Total;
                        self.pagination.defaultPageSize = data.data.RowCount;
                        self.pagination.current = data.data.PageIndex;
                    }
                };
                http.Post(op);
            }
        },
        mounted() {
            this.getTableData();
        },
        computed: {}

    };
</script>