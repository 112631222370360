<template>
   <a-layout-content>
                <div   style="background:#fff;padding:20px;  min-width: min-content;">
                    <a-row class="yj-form">
                        <a-col :span="24">
                            <label>问题描述 :</label>
                            <a-input id="name"
                                     contextmenu="2455"
                                     v-model="search"
                                     style="width:260px" placeholder="请输入问题描述"></a-input>
                            <a-button type="primary" @click="getTableData">查询</a-button>
                            <a-button type="primary" style="float:right" @click="add();">新建问题</a-button>
                        </a-col>
                    </a-row>

                    <a-table :columns="columns"
                             rowKey="ID"
                             :data-source="tableData"
                             :pagination="pagination"
                             @change="pagerChange"
                             class="yj-table-img">

                        <template slot="operation" slot-scope="text, record">

                            <a href="#" @click="onEdit(record.ID)" style="margin-right:20px;">编辑</a>

                            <a href="#" @click="onDelete(record.ID)">删除</a>

                        </template>
                    </a-table>
                </div>
            </a-layout-content>
</template>
<style type="text/css">
   
    #title {
        height: 200px;
    }
</style>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";

    import { message } from 'ant-design-vue'

    export default {
        name: "QuestionList",
        components: {
       
        },
        data() {
            return {
                columns: [
                    {
                        title: "问题描述",
                        dataIndex: "Title",
                        width: 250,
                        ellipsis: true,                        
                    },
                    {
                        title: "创建时间",
                        width: 150,
                        customRender: (text, item) => {

                            var temp = new Date(util.TimeStamptoDateTime(item.CreateTime, 'yyyy-MM-dd hh:mm:ss'));

                            return util.formatDate3(temp);
                        }
                    },
                    {
                        title: "访问次数",
                        dataIndex: "ViewCount",
                        width: 150, align: "center"
                    },
                    {
                        title: "操作",
                        dataIndex: "operation",
                        scopedSlots: { customRender: 'operation' },
                        width: 250
                    }
                ],
                modelDisplay: false,
                search: '',
                tableData: [],
                id: "",
                title: "",
                brief: "",
                pagination: {
                    total: 0,
                    defaultPageSize: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                }          
            }
        },
        methods: {
            add: function () {
                var self = this;

                //self.$emit("addQuesion", "");

                self.$router.push({
                    name: "ShopManage_QuestionDetail", query: {
                        showName:'QuestionDetail'
                    }
                })
            },

            pagerChange: function (item) {
                var self = this;

                self.pagination.current = item.current;
                self.getTableData();
            },

            onEdit: function (data) {

                var self = this;

               // self.$emit("editQuesion", data);

                self.$router.push({
                    name: "ShopManage_QuestionDetail", query: {                        
                        id: data
                    }
                })
            },

            onDelete: function (data) {

                var self = this;
                self.$confirm({
                    title: '确定删除该问题吗',
                    content: '',
                    okText: '是',
                    okType: 'danger',
                    cancelText: '否',
                    onOk() {
                        self.deleteQuestionDetail(data);
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });
            },

            deleteQuestionDetail: function (id) {
                var self = this;
                var op = {
                    url: "/ShopModule/ShopManage/DelShopQuestion",
                    data: {
                        ID: id,
                    },
                    OnSuccess: function (data) {
                        if (data.data == "True") {

                            message.success(
                                '删除成功',
                                1.5,
                                function () {
                                    self.getTableData();

                                    //self.$router.push({
                                    //    name: "ShopManage_ShopManage", query: {
                                    //        showName: "QuestionList"
                                    //    }
                                    //})
                                }
                            );
                        }
                    }
                };
                http.Post(op);
            },

            updateQuestionDetail: function () {
                var self = this;
                var op = {
                    url: "/ShopModule/ShopManage/UpdateShopQuestion",
                    data: {
                        ID: self.id,
                        Title: self.title,
                        AnswerText: self.brief,
                        CustomerID: "dsi201706150003"
                    },
                    OnSuccess: function (data) {

                        self.modelDisplay = false
                        if (data.data == "True") {
                            message.success(
                                '编辑成功',
                                2,
                            );
                        }
                    }
                };
                http.Post(op);

            },

            getQuestionDetail: function (id) {
                var self = this;
                var op = {
                    url: "/ShopModule/ShopManage/GetShopQuestionDetail",
                    data: {
                        id: id
                    },
                    OnSuccess: function (data) {
                        console.log(1000);
                        console.log(data);
                        self.id = data.data.ID;
                        self.title = data.data.Title;
                        self.brief = data.data.AnswerText;
                        self.modelDisplay = true;
                    }
                };
                http.Post(op);
            },

            getTableData: function () {
                var self = this;
                var op = {
                    url: "/ShopModule/ShopManage/GetShopQuestionList",
                    data: {
                        pageIndex: self.pagination.current,
                        title: self.search
                    },
                    OnSuccess: function (data) {
                        self.tableData = data.data.Data;
                        self.pagination.total = data.data.PageCount;
                        self.pagination.defaultPageSize = data.data.RowCount;
                        self.pagination.current = data.data.PageIndex;
                    }
                };
                http.Post(op);
            }
        },
        mounted() {
            this.getTableData();
        },
        computed: {}

    };
</script>