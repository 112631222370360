<template>
 



 
      <a-form-model ref="ruleForm" style="height:100%; "  :model="formModel" :rules="rules">
        <a-row style="height:100%;"  >
          <a-col  :span="3" style="background: #fff; height: 100%;overflow-y: auto;" :sm='3'>
            <h4 style="text-align: center">选择模板</h4>




            <a-form-model-item
              style="  cursor: pointer"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <div class="yj-storecon-template" style=" overflow: auto;" :data="templates">
                <div……
                  v-for="item in templates"
                  v-bind:key="item.ID"
                  @click="chooseTemplate(item.ID)"
                  :class="{ active: item.ID == CurrentTemplate.TemplateID }"
                >
                  <div class="yj-storecon-template-on">
                    <a-icon type="check-circle" theme="filled"></a-icon>
                  </div>
                  <div class="yj-storecon-template_img">
                    <img :src="item.BackgroudImg" />
                  </div>
                  <p>{{ item.Name }}</p>
                </div……>
              </div>
            </a-form-model-item>
          </a-col>
          <a-col  :span="15" :lg='15'       style="margin: 0 0px; padding: 40px 0; text-align: center" >
            <!--<div class="ay-store_img">-->
            <div class="phone-frame" style="max-width: 350px; margin: 0 auto">
              <a
                class="return-btn"
                href="javascript:;"
                onclick="window.history.go(-1)"
              >
           <img class="r-button" width='16' src="/Image/returns.svg"/>  返回
              </a>
              <a class="share-btn" href="##" style="visibility: hidden">
                <img src="/Image/share-icon.png" />
              </a>
              <iframe
                id="home"
                name="home"
                :src="CurrentTemplate.TemplateUrl"
                frameborder="0"
                width="100%"
                height="650"
                style="background:#fff; border-radius:0 0 25px 25px;overflow: hidden;margin-top:57px;"
              ></iframe>
              <!--</div>-->
            </div>
           
            <div style="margin:12px 0 0 0;"> 
                 <a-button
          size="large"
          style="margin: 0 15px"
          type="primary"
          @click="save('ruleForm')"
          >保存</a-button>
                 <a-button size="large"  type="primary" centered Vertically class="div-preview margin-r"
          > <img src="../../../../../public/Image/icon-phon.svg" /> 预览      <div class="div-preview-fd">
                <img :src="viewImgUrl" style="width: 170px; height: 170px" />
                <p>打开微信扫一扫</p>
              </div> </a-button
        >
       </div>
          </a-col>
          <a-col :span="6" :sm='6' style="padding: 10px;height:fit-content;overflow: auto;background-color: #fff;height: 100%;">
              <h3>基本信息</h3>
            <a-form-model-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              prop="name"
            >
              <a-input
                placeholder="店铺名称"
                v-model="formModel.name"
              ></a-input>
            </a-form-model-item>

            <h4>首页大图</h4>
            <a-form-model-item
              style="margin-bottom: 0px"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <a-upload
                name="image"
                :data="ParamData"
                list-type="picture-card"
                class="avatar-uploader"
                action="/FileServer/Upload"
                :remove="removeImg"
                :fileList="formModel.fileList"
                @change="imgChange"
              >
                <div v-if="formModel.fileList.length < 8">
                  <a-icon type="plus" />
                  <div class="ant-upload-text">上传图片</div>
                </div>
              </a-upload>
            </a-form-model-item>
            <h4>店铺简介</h4>
            <a-form-model-item
              style="margin-bottom: 0px"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
            >
              <Rich
                v-bind:value.sync="formModel.brief"
                style="width: 100%"
              ></Rich>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
 

    <!-- <a-layout-footer style="height:60px;padding:8px 0 ; ">

                    <a-row type="flex"  justify="center" >

                        <a-col :span="6" style="text-align:center">
                            <a-button size="large"  style="margin:0 15px"  type="primary" @click="save('ruleForm')">保存</a-button>
                                
                    
                            <a-button size="large" centered Vertically class="margin-r">取消</a-button>

                        </a-col>
                    </a-row>
 
                     
</a-layout-footer> -->
 
</template>

<script type="text/javascript">
import http from "@/Plugin/Http.js";
import Rich from "@/components/RichEditor.vue";

import { message } from "ant-design-vue";

export default {
  name: "BaseInfor",
  components: {
    Rich,
  },
  data() {
    return {
      CurrentTemplate: {
        TemplateDefaultUrl: "",
        TemplateUrl: "", //http://mptest.ay001.net/CommonProduct/ShopHome/IndexTemplate?CustomID=dsi201907290012&IntroducerID=uat20200429000006
        TemplateID: -1,
      },
      modalVisible: false,
      viewImgUrl: "",
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 3 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 24 },
        },
      },
      ParamData: {
        ProcessorName: "Image",
        JsonDataParams: "",
      },
      templates: [],
      formModel: {
        templateid: "",
        name: "",
        brief: "",
        fileList: [],
      },
      rules: {
        name: [
          {
            validator: this.validateUserName,
            trigger: "change",
            required: true,
          },
        ],
      },
    };
  },

  methods: {
    getStoreView() {
      var self = this;
      var op = {
        url: "/ShopModule/ShopManage/GetStoreViewQr",
        data: {},
        OnSuccess: function (data) {
          self.viewImgUrl = data.data;
        },
      };
      http.Post(op);
    },
    chooseTemplate: function (data) {
      var self = this;
      //self.CurrentTemplate.TemplateUrl = 'http://mptest.ay001.net/CommonProduct/ShopHome/IndexTemplate?CustomID=dsi201907290012&IntroducerID=uat20200429000006&templateid=' + data;
      self.CurrentTemplate.TemplateID = data;
      self.CurrentTemplate.TemplateUrl =
        self.CurrentTemplate.TemplateDefaultUrl +
        "&templateid=" +
        self.CurrentTemplate.TemplateID;
      console.log(self.CurrentTemplate.TemplateUrl);
    },
    onRadioChange: function (data) {
      var self = this;
      var val = data.target.value;
      console.log(data.target);
      if (val == "b") {
        self.$router.push({ name: "ShopManage_QuestionList" });
      } else if (val == "c") {
        self.$router.push({ name: "ShopManage_SiteList" });
      }
      console.log(data);
    },
    imgChange: function (info) {
      var self = this;

      if (info.file.status === "error") {
        if (info.file.response && info.file.response.Message) {
          this.$message.error(info.file.response.Message);
        } else {
          this.$message.error(`${info.file.name} 文件上传失败`);
        }
        return;
      }
      if (info.file.status === "done") {
        info.file.url = info.file.response;
        // this.$message.success(`${info.file.name} 文件上传成功`);
        // console.log(info.file.response);
      }
      // console.log(22222);
      // console.log(info);
      self.formModel.fileList = info.fileList;
    },
    removeImg: function (data) {
      //var self = this;
      console.log(data);
      //self.formModel.fileList = data.fileList;
    },
    onEditorReady: function () {},
    onEdiorChange: function () {},
    validateUserName: function (rule, value, callback) {
      console.log("999");
      if (value === "") {
        callback(new Error("请输入名称"));
      } else {
        callback();
      }
    },
    getData: function () {
      var self = this;
      var op = {
        url: "/ShopModule/ShopManage/GetShopManageDetail",
        data: {
          custmerid: "dsi201909240001",
        },
        OnSuccess: function (data) {
          console.log("global");
          console.log(data);
          self.formModel.name = data.data.Name;
          self.formModel.brief = data.data.Brief;

          self.formModel.fileList = data.data.UploadImgs;
          self.formModel.templateid = data.data.TemplateID;
          self.CurrentTemplate.TemplateID = data.data.TemplateID;
          self.CurrentTemplate.TemplateDefaultUrl = data.data.TemplatePath;
          self.CurrentTemplate.TemplateUrl =
            self.CurrentTemplate.TemplateDefaultUrl +
            "&templateid=" +
            self.CurrentTemplate.TemplateID;
        },
      };
      http.Post(op);
    },
    getTemplateData: function () {
      var self = this;
      var op = {
        url: "/ShopModule/ShopManage/GetTemplateList",
        data: {
          // custmerid: 'dsi201909240001'
        },
        OnSuccess: function (data) {
          self.templates = data.data;
        },
      };
      http.Post(op);
    },
    save: function (formName) {
      var self = this;
      console.log(self.formModel.brief);

      this.$refs[formName].validate((valid) => {
        if (valid) {
          var op = {
            url: "/ShopModule/ShopManage/UpdateShopMange",
            data: {
              Name: self.formModel.name,
              Brief: self.formModel.brief,
              UploadImgs: self.formModel.fileList,
              TemplateID: self.CurrentTemplate.TemplateID,
            },
            OnSuccess: function (data) {
              console.log(data);
              if (data.data == "True") {
                message.success("保存成功", 2);
              }
            },
          };
          http.Post(op);
        } else {
          return false;
        }
      });
    },
  },
  mounted() {
    var self = this;
    self.getData();
    self.getTemplateData();

    self.getStoreView();
  },
  computed: {},
};
</script>
<style type="text/css">

.phone-frame {
 
 
  width: 100%;
  height: 720px;
  padding:0 15px;
  position: relative;
  overflow: hidden;
  background: url("/Image/iphone.png") no-repeat top center/100% 100%;
  background-size: 100%;
}

.return-btn {
  position: absolute;
  left: 20px;
  top: 25px;
  color:#333;

}
.r-button{vertical-align: text-bottom;}
.share-btn {
  position: absolute;
  right: 0;
  top: 30px;
}

.yj-storecon-template {
  height: auto;
  max-width: 100%;
}

.yj-storecon-template > div {
  position: relative;
  height: auto;
  width: auto;
  padding: 6px;
  overflow: hidden;
  text-align: center;
  flex-shrink: 0;
  font-size: 0.66rem;
  margin: 0 auto;
}

.yj-storecon-template-on {
  display: none;
}

.yj-storecon-template > div.active .yj-storecon-template-on {
  display: block;
  position: absolute;
  content: "";
  font-size: 28px;
  z-index: 6;
  left: 50%;
  top: 50%;
  margin-left: -16px;
  margin-top: -16px;
  color: #2281f8;
  opacity: 0.8;
}

.yj-storecon-template > div img {
  height: 100%;
  object-fit: cover;
}

.yj-storecon-template > div p {
  width: 96%;
  margin-left: 2%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 1.4rem;
  line-height: 1.4rem;
  margin-bottom: 0;
}

.yj-storecon-template_img {
  height: 100px;
}
/* .yj-storecon-template > div.active p{display:none;}*/
.yj-storecon-template > div.active img {
  transform: scale(1);
}

/* .div-preview {
  background-color: #6f6f6e;
  padding: 4px 14px;
  width: max-content;
  color: #fff;
  border-radius: 2px;
  margin: 6px auto 0;
  position: relative;
} */

.div-preview img {
  height: 16px;
  vertical-align: middle;
  margin-top: -3px;
}

.div-preview-fd {
  position: absolute;
  z-index: 99999;
  top: -212px;
  left: -52px;
  width: 200px;
  height: 208px;
  background-color: #fff;
  box-shadow: #999 0 2px 6px;
  padding: 12px;
  overflow: hidden;
  display: none;
  color: #6f6f6e;
}

.div-preview-fd img {
  width: 100%;
  object-fit: cover;
}

.div-preview:hover .div-preview-fd {
  display: block;
}
</style>


