<template>
  <div style="height:calc(100% - 52px)">
    <a-row justify="space-between" style="background: #fff; padding:10px">
      <!-- <a-col >
        <a-radio-group :default-value="selectVal" @change="onRadioChange">
          <a-radio-button value="a"> 基本设置 </a-radio-button>
          <a-radio-button value="b"> 常见问题 </a-radio-button>
          <a-radio-button value="c"> 门店地址 </a-radio-button>
        </a-radio-group>
      </a-col> -->
      <!-- <a-col :span="4" style="text-align: right">
        <a-button size="large" centered Vertically class="margin-r"
          >取消</a-button
        >
        <a-button
          size="large"
          style="margin: 0 15px"
          type="primary"
          @click="save('ruleForm')"
          >保存</a-button
        >
      </a-col> -->
    </a-row>
<div style="height: 100%;" >
    <QuestionList v-if="showName == 'QuestionList'"></QuestionList>
    <SiteList v-if="showName == 'SiteList'"></SiteList>

    <BaseInfor v-if="showName == 'BaseInfor'"></BaseInfor>
    </div>
  </div>
</template>

<script type="text/javascript">
import BaseInfor from "./components/P_BaseInfor";
import SiteList from "./components/P_SiteList";
import QuestionList from "./components/P_QuestionList";

export default {
  name: "ShopManage",
  components: {
    BaseInfor,
    SiteList,
    //SiteDetail,
    QuestionList,
    //QuestionDetail
  },
  data() {
    return {
      showName: "BaseInfor",
      selectVal: "c",
      questionID: "",
    };
  },

  methods: {
    addSite: function () {
      self.showName = "SiteDetail";
    },
    editSite: function (data) {
      console.log(data);
      self.showName = "SiteDetail";
    },
    editQuesion: function (data) {
      var self = this;

      self.questionID = data;
      console.log(self.questionID);
      self.showName = "QuestionDetail";
    },
    addQuesion: function () {
      var self = this;
      self.showName = "QuestionDetail";
    },
    paramsReady: function () {
      var self = this;

      if (self.$route.query.showName != undefined) {
        self.showName = self.$route.query.showName;
      }
      //self.selectVal = "a";

      if (self.showName == "BaseInfor") {
        self.selectVal = "a";
      }

      if (
        self.showName == "QuestionList" ||
        self.showName == "QuestionDetail"
      ) {
        self.selectVal = "b";
      }

      if (self.showName == "SiteList" || self.showName == "SiteDetail") {
        self.selectVal = "c";
      }
      console.log(self.selectVal);
    },
    onRadioChange: function (data) {
      var self = this;
      var val = data.target.value;
      if (val == "a") {
        //基本信息
        self.showName = "BaseInfor";
      } else if (val == "b") {
        //常见问题
        self.showName = "QuestionList";
      } else if (val == "c") {
        //地点信息
        self.showName = "SiteList";
      }
    },
  },
  mounted() {},
  created() {
    this.paramsReady();
  },
  computed: {},
};
</script>
<style scoped>
::-webkit-scrollbar {
  width: 10px;
  cursor: default;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  overflow: auto;
}
::-webkit-scrollbar-thumb {
  background-color: #d2d3d4;
  min-height: 25px;
  min-width: 25px;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
}
::-webkit-scrollbar-track {
  background-color: #f2f3f4;
  border: 1px solid #efefef;
}
</style>
<!--<style type="text/css">

    .phone-frame {
        border: 1px solid #d5d5d5;
        border-radius: 10px;
        width: 100%;
        height: 568px;
        position: relative;
        overflow: hidden;
        background: url('/assets/img/modules.png') no-repeat top center;
        background-size: 100%;
    }

    .return-btn {
        position: absolute;
        left: 0;
        top: 30px;
    }

    .share-btn {
        position: absolute;
        right: 0;
        top: 30px;
    }

    .yj-storecon-template > div {
        height: 100px;
        width: 100px;
        overflow: hidden;
        text-align: center;
        flex-shrink: 0;
        filter: grayscale(80%);
    }
</style>-->


